/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
#home {
  width: 50.83333333%;
  margin: 18px 0 18px 65px;
}
.desk {
  max-width: 900px;
}
body {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.content,
.footer {
  -webkit-flex: 1 0 auto;
  flex: 1 0 auto;
}
.navigation,
.aside,
.banner {
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
}
.aside,
.footer {
  width: 100%;
  -webkit-align-self: flex-end;
  align-self: flex-end;
}
.content {
  min-height: 40vh;
}
.contact {
  float: left;
  width: auto;
  margin-left: 5%;
}
.aside__claim {
  margin-top: 27px;
  margin-right: 2.5%;
  width: 47%;
  float: right;
}
@media (max-width: 979px) {
  .partner {
    clear: both;
    float: left;
    position: relative;
    width: 100%;
    margin: 27px 0 13px;
    text-align: center;
  }
  .partner__logo {
    height: 100px;
    width: 29%;
    margin: 0;
  }
}
.footer {
  padding: 17px 0 17px;
}
#home:before {
  width: 42px;
  height: 46px;
}
#home:after {
  top: -10px;
  right: -100px;
  content: '';
  z-index: 1;
  position: absolute;
  background: url(/images/edelweiss.svg) no-repeat;
  background-size: 100%;
  width: 62px;
  height: 67px;
}
.copyright:before {
  left: 60px;
  width: 33px;
  height: 36px;
}
.copyright:after {
  left: 98px;
  width: 42px;
  height: 46px;
}
.area {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 1.28205128%;
  margin-left: 1.28205128%;
  width: 97.43589744%;
}
.area h2,
.area .foot {
  margin-right: 2.63157895%;
  margin-left: 2.63157895%;
}
.area .part,
.area > .grid table {
  margin-right: 2.63157895%;
  margin-left: 2.63157895%;
  width: 94.73684211%;
}
.area .tiny {
  width: 44.73684211%;
}
.area > .slim {
  width: 47.43589744%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 5.40540541%;
  margin-left: 5.40540541%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 89.18918919%;
}
.area > .slim .tiny {
  width: 89.18918919%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
.area {
  margin-left: 1.25%;
  width: 97.5%;
}
.unit .head {
  margin-top: 24px;
}
.cb-layout1 .slim .pict + .lead {
  margin-top: 18px;
}
.cb-layout1 .slim .lead + .text {
  margin-top: 1em;
}
.cb-layout1 .slim .text + .link {
  margin-top: 3.1em;
}
.part:last-child {
  margin-bottom: 30px;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-medium.css.map */